import React from 'react';
import { CSSTransition } from 'react-transition-group';
// Templates
import { ModalShowcaseItem } from '../../templates/Modals/ModalShowcaseItem';
// Style
import {
  Body,
  Close,
  Container,
  ModalWrapper,
  Underlay
} from './Modal.styles';

const Modal = ({
  data,
  open,
  toggleModal
}) => {

  const closeModal = (e) => {
    toggleModal();
  }

  return(
    <CSSTransition
      in={open}
      timeout={200}
      classNames="fadeIn"
    >
      <Container open={open}>
        <Underlay onClick={closeModal} />
        <ModalWrapper>
          <Body>
            <ModalShowcaseItem data={data} />
          </Body>
          <Close onClick={toggleModal}>
            <i className="fas fa-times"></i>
          </Close>
        </ModalWrapper>
      </Container>
    </CSSTransition>
  )
}

export default Modal;