import styled from 'styled-components';
import { ShadowedBox } from '../ui.styles';

const Section = styled.section`
  margin-bottom: 70px;
`;

export const Intro = styled(Section)`

`;

export const Sites = styled(Section)`

`;

export const Testimonials = styled(Section)`

`;

export const BlurbBox = styled.div`
  ${ShadowedBox};
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 240px;
    height: auto;
    margin: 0 0 20px 0;

    @media (min-width: 768px) {
      max-width: 100%;
      flex-basis: 33%;
      margin: 0 20px 0 100px;
    }

    @media (min-width: 1200px) {
      max-width: 100%;
      flex-basis: 33%;
      margin: 0 40px 0 140px;
    }
  }
`;

export const BlurbText = styled.div`

  @media (min-width: 768px) {
    flex: 1;
  }

  p {
    font-size: 2rem;
    line-height: 130%;
    letter-spacing: .5px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
`;

export const WebsiteBoxes = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
`;
