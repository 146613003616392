import styled from 'styled-components';
import { colors as c } from '../../../styles/colors';

export const Container = styled.div`
`;

export const Figure = styled.figure`
  margin-bottom: 3rem;

  @media (min-width: 550px) {
    padding: 0 100px;
  }

  .gatsby-image-wrapper {
    margin-bottom: 2rem;

    img {
      height: auto;
      border: 1px solid ${c.grayBorder};
    }
  }
`;

export const Title = styled.h1`
  font-size: 2.6rem;
  margin-bottom: 6px;
  text-align: center;

  i {
    font-size: 0.6em;
    vertical-align: top;
  }
`;

export const Role = styled.h2`
  font-size: 1.6rem;
  color: ${c.grayText};
  text-align: center;
`;

export const Description = styled.div`

  p {
    font-size: 2rem;
    line-height: 130%;
    letter-spacing: .5px;
    text-align: center;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
`;