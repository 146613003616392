import styled from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1337;
  width: 100%;
  height: 100%;
  padding: 15px;

  ${({ open }) => open && `
    display: flex;
  `}

  &.fadeIn {

    &-enter {
      opacity: 0;
    }

    &-enter-active {
      opacity: 1;
      transition: opacity 200ms;
    }

    &-exit {
      opacity: 1;
    }

    &-exit-active {
      opacity: 0;
      transition: opacity 200ms;
    }
  }
`;

export const Underlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.7);
`;

export const ModalWrapper = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 600px;
  max-height: 100%;
`;

export const Body = styled.div`
  background-color: ${c.grayBGPrimary};
  padding: 20px;
  overflow-y: scroll;
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
`;

export const Close = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 45px;
  height: 45px;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-size: 3rem;
  line-height: 1;
  color: ${c.grayText};
  border: 1px solid transparent;
  border-radius: 8px;
  transition: border-color .1s linear;

  &:hover {
    border-color: ${c.grayLine};
  }
`;