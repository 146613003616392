import React from 'react'
// Style
import {
  Container
} from './QuoteBox.styles';

const QuoteBox = () => {

  return(
    <Container>
      <blockquote>We provided Dave with designs of the landing lages we wanted to build. Dave dived into the designs and took the initiative on both desktop and mobile conversion while keeping us involved at every relevant step. He was brilliant in communicating and double checking with us when he faced challenges while independently solving them. Both the collaboration and end results were beyond our expectations, perfect even.</blockquote>
      <figcaption>François (Design and Marketing at Parachute)</figcaption>
    </Container>
  )
}

export default QuoteBox