import React from 'react';
// Components
import Img from 'gatsby-image';
// Functions
import parse from 'html-react-parser';
// Style
import {
  Container,
  Description,
  Figure,
  Role,
  Title
} from './ModalShowcaseItem.styles';

const ModalShowcaseItem = ({
  data
}) => {

  return(
    <Container>
      {data.image &&
        <>
          <Figure>
            <Img fluid={data.image.childImageSharp.fluid} alt={data.alt} />
            <figcaption>
              <Title><a href={data.url}>{data.title} <i className="fas fa-external-link-alt"></i></a></Title>
              <Role>{data.role}</Role>
            </figcaption>
          </Figure>
          <Description>{parse(data.description)}</Description>
        </>
      }
    </Container>
  )
}

export default ModalShowcaseItem;