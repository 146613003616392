import styled from 'styled-components';
import { ShadowedBox } from '../../styles/ui.styles';
import quoteIcon from '../../images/icons/quote.svg';

export const Container = styled.figure`
  ${ShadowedBox};
  position: relative;
  max-width: 700px;
  margin: 0 auto;
  padding: 40px;
  font-size: 2rem;
  line-height: 1.3;

  ::before {
    position: absolute;
    content: '';
    top: -22px;
    left: -13px;
    width: 50px;
    height: 43px;
    background-image: url('${quoteIcon}');
    background-repeat: no-repeat;
    background-position: center;
  }

  blockquote {
    font-style: italic;
    margin-bottom: 28px;
  }

  figcaption {
    text-align: right;
  }
`;