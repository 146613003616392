import React, { useState } from "react"
// Components
import { StaticQuery,graphql } from 'gatsby';
import Layout from "../components/layout"
import { Seo } from "../components/Seo"
import { QuoteBox } from "../components/QuoteBox"
import { Container1200 } from '../components/Container1200';
import { PageTitle } from '../components/PageTitle';
import { ShowcaseItem } from '../components/ShowcaseItem';
import { TitleWithLine } from '../components/TitleWithLine';
import { WebDevImage } from '../components/WebDevImage';
import { Modal } from '../components/Modal';
// Images
// import macbook from '../images/web-development/macbook-webdev.png';
// Style
import {
  BlurbBox,
  BlurbText,
  Intro,
  Sites,
  Testimonials,
  WebsiteBoxes
} from '../styles/pages-styles/web-development.styles';

const WebDevelopment = () => {
  const query = graphql`
    query WebsitesItemsQuery {
      allWebsitesJson {
        edges {
          node {
            alt
            description
            id
            image {
              childImageSharp {
                fluid(maxWidth: 384
                  quality: 100
                  traceSVG: { color: "#BDBDBD" }
                ) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
            linkType
            role
            slug
            title
            url
          }
        }
      }
    }
  `;
  
  const [modalOpen,setModalOpen] = useState(false);
  const [modalData,setModalData] = useState({});

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  }

  return (
    <>
      <Layout>
        <Seo title="Web Development | DaveJudd.com" />
        <Intro id="intro">
          <Container1200>
            <PageTitle>Web Development</PageTitle>
            <BlurbBox>
              <BlurbText>
                <p>I began developing websites as a hobby around 2005. I have done full stack freelance development for clients around the globe since 2008, and have been working professionally as a front end developer for about 10 years. Check out my most recent projects below.</p>
                <p>If you have a project or idea you would like to discuss, feel free to reach out!</p>
              </BlurbText>
              <WebDevImage />
            </BlurbBox>
          </Container1200>
        </Intro>
        <Sites id="photos">
          <Container1200>
            <TitleWithLine>Recent Projects</TitleWithLine>
            <WebsiteBoxes>
              <StaticQuery
                query={query}
                render={data => (
                    <>
                      {data.allWebsitesJson.edges.map(({ node }) => (
                          <ShowcaseItem
                            key={node.slug}
                            image={node.image.childImageSharp.fluid}
                            linkType={node.linkType}
                            onClick={() => {
                              setModalData(node);
                              toggleModal();
                            }}
                            target={node.target}
                            to={node.url}
                          >
                            <h2>{node.title}{node.linkType === 'external' && <i className="fas fa-external-link-alt"></i>}</h2>
                          </ShowcaseItem>
                        )
                      )}
                    </>
                )}
              >
              </StaticQuery>
            </WebsiteBoxes>
          </Container1200>
        </Sites>
        <Testimonials id="testimonials">
          <Container1200>
            <TitleWithLine>Testimonials</TitleWithLine>
            <QuoteBox />
          </Container1200>
        </Testimonials>
      </Layout>
      <Modal data={modalData} open={modalOpen} toggleModal={toggleModal} />
    </>
  )
}

export default WebDevelopment;
